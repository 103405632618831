import { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { getFallbackLanguage } from '@/helpers/lang';
import { SportCategory } from '@/pages/sports';
import { LiveIcon } from '@/theme/Icons';
import useTranslation from 'next-translate/useTranslation';
import { DomainLicense } from '@/utils/multiDomains';

export function useSportCategoryTabs(
  license: keyof DomainLicense | undefined = 'F'
) {
  const { lang } = useTranslation();
  const [sportCategories, setSportCategories] = useState<SportCategory[]>([]);

  useEffect(() => {
    if (license !== 'F') return;

    const sportCategoryTabs = async () => {
      try {
        const sportCategories: Response = await fetch(
          `/api/cms/sport-categories?locale=${getFallbackLanguage(lang)}`
        );
        const categories = await sportCategories.json();
        if (Array.isArray(categories)) {
          setSportCategories(categories);
        }
      } catch (err) {
        console.error("Can't load sport categories", err);
      }
    };
    void sportCategoryTabs();
  }, [lang, license]);

  const menuItems = useMemo(() => {
    return sportCategories?.map((item) => ({
      id: item.id,
      optionName: item.attributes.customCssClass,
      name: item.attributes.name,
      url: item.attributes.redirectUrl,
      target: item.attributes.redirectTarget,
      order: item.attributes.order,
      children: item.attributes.children?.map((subItem) => ({
        id: subItem.id,
        name: subItem.name,
        url: subItem.redirectUrl,
        target: subItem.redirectTarget
      }))
    }));
  }, [sportCategories]);

  const menuItemsOptions = {
    live: {
      icon: <LiveIcon width={6} />
    }
  };

  return {
    sportCategories,
    menuItems,
    menuItemsOptions
  };
}
